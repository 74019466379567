import { Component, HostBinding, NgZone } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AppServiceService } from './api/app-service.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AlertController } from '@ionic/angular';

//For One Signal Fix
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RequestService } from 'src/app/core/request.service';
import { environment } from 'src/environments/environment';
import { ModalPopoverPage } from "./views/web-views/shared/modal-popover/modal-popover.page";

// Network
import { Network } from '@ionic-native/network/ngx';
import { PopupPage } from './views/modals/popup/popup.page';
// import { StylishAlertPage } from './views/modals/stylish-alert/stylish-alert.page';

import consts from "../app/views/web-views/shared/constatnts.json";

declare var config: any;
declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public viewType: any;
  public baseURL = environment.BASE_URL;
  public pages: any = {};

  public PRIVACY_POLICY_SLUG = 'pnp';
  public TERMS_AND_CONDITIONS_SLUG = 'tnc';

  public targetPage: string = '';

  public internetConnection: boolean = true;
  public noInternet: any;

  POPOVER_TYPES = consts.POPOVER_TYPES;

  @HostBinding('style.--loading-spinner-rgb')
  public colorSpinner: any = this.appService.appObject?.theme[this.appService.themeConfigFor]?.spinnerColor ? this.appService.appObject?.theme[this.appService.themeConfigFor]?.spinnerColor : '255, 7, 28';

  @HostBinding('style.--side-menu-bakcground')
  public sideMenuBackground: any = this.appService.appObject?.theme[this.appService.themeConfigFor]?.sideMenu?.background ? this.appService.appObject?.theme[this.appService.themeConfigFor]?.sideMenu?.background : '#ff071c';

  @HostBinding('style.--select-list-primary')
  public selectListColor: any = this.appService.appObject?.theme[this.appService.themeConfigFor]?.primaryColor ? this.appService.appObject?.theme[this.appService.themeConfigFor]?.primaryColor : '';

  private isFavoriteRestaurantPopUpOpen: boolean = false;
  constructor(
    public appService: AppServiceService,
    private platform: Platform,
    private callNumber: CallNumber,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public oneSignal: OneSignal,
    public requestService: RequestService,
    public router: Router,
    private modalController: ModalController,
    private route: ActivatedRoute,
    public zone: NgZone,
    public network: Network,
    public alertController: AlertController
  ) {
    this.network.onDisconnect().subscribe(() => {
      // this.appService.showToast('No internet connection', 'danger', 'top', 1000);
      this.zone.run(() => {
        this.getTranslateText();
        this.internetConnection = false;
      });
    });
    // watch network for a connection
    this.network.onConnect().subscribe(() => {
      // this.appService.showToast('Internet connected', 'success', 'top', 500);
      this.zone.run(() => {
        this.internetConnection = true;
      });
    });
    route.queryParams.subscribe((parameters) => {
      // console.log('parameters : ' , parameters);
      if (parameters.target) {
        this.targetPage = parameters.target;
      }
    });
    //For One Signal Fix
    this.initializeApp();

    let watcher = setInterval(async () => {
      if (this.appService.isSettingsLoaded) {
        if (this.appService.appObject.settings.is_franchise === 'true' && this.appService.appObject.settings.app_configuration?.has_multiple_restaurants && !this.appService.appObject.favouriteRestaurant) {
          // console.log('document.location.pathname : ', document.location.pathname);
          if (!this.appService.isWebVersion) {
            const modal = await this.modalController.create({
              component: PopupPage,
              cssClass: 'custom-popup',
              componentProps: {
                'currentUI': 'favourite-restaurant'
              }
            });
            if (!this.isFavoriteRestaurantPopUpOpen) {
              await modal.present();
              this.isFavoriteRestaurantPopUpOpen = true;
            } else {
              // console.log('else called');
            }
          } else {
            if (document.location.pathname === '/home') {
              const modal = await this.modalController.create({
                component: ModalPopoverPage,
                cssClass: 'custom-popup',
                componentProps: {
                  'type': this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECT.TYPE,
                  'title': this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECT.TITLE
                },
                keyboardClose: false,
              });

              if (!this.isFavoriteRestaurantPopUpOpen) {
                await modal.present();

                modal.onDidDismiss().then(async (response) => {
                  if (response.data && response.data.status) {
                    const cmodal = await this.modalController.create({
                      component: ModalPopoverPage,
                      cssClass: 'my-custom-class',
                      componentProps: {
                        'title': this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TITLE,
                        'type': this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TYPE,
                        'selectfavouriteRestaurant': true
                      }
                    });
                    await cmodal.present();
                  }
                })
                this.isFavoriteRestaurantPopUpOpen = true;
              } else {
                // console.log('else called');
              }
            }
          }
        }
        clearInterval(watcher);
      }
    }, 1000);

    // set spinner color from backend
    let spinnerColor = setInterval(() => {
      if (this.appService.isSettingsLoaded) {
        if (this.appService.appObject.theme[this.appService.themeConfigFor]?.spinnerColor) {
          // transform hexa value to RGB
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.appService.appObject.theme[this.appService.themeConfigFor]?.spinnerColor);
          this.colorSpinner = (parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16));
        }

        if (this.appService.appObject.theme[this.appService.themeConfigFor]?.sideMenu?.background) {
          this.sideMenuBackground = this.appService.appObject.theme[this.appService.themeConfigFor]?.sideMenu?.background;
        }
        clearInterval(spinnerColor);
      }
    }, 1000);
  }

  ngDoCheck() {
    if (this.appService.appObject.theme[this.appService.themeConfigFor]?.spinnerColor) {
      // transform hexa value to RGB
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.appService.appObject.theme[this.appService.themeConfigFor]?.spinnerColor);
if(result && result.length >= 4)
this.colorSpinner = (parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16));
    }

    if (this.appService.appObject.theme[this.appService.themeConfigFor]?.sideMenu?.background) {
      this.sideMenuBackground = this.appService.appObject.theme[this.appService.themeConfigFor]?.sideMenu?.background;
    }
  }

  async initializeApp() {
    this.platform.ready().then(async () => {

      this.splashScreen.show();
      await this.appService.appInit();

      // open slug if request contain 
      if (this.targetPage && this.appService.isWebVersion && this.appService.appObject.settings && this.appService.appObject.settings.pages) {
        let page = this.appService.appObject.settings.pages.find((page) => page.slug === this.targetPage);
        if (page) {
          this.openPage(page.slug, page.name);
        }
      }


      let favIcon: any = document.getElementById('appFavIcon');
      if (favIcon && this.appService.appObject.favIcon) favIcon.href = this.appService.appObject.favIcon;

      // this.splashScreen.hide();
      this.statusBar.overlaysWebView(true);
      setTimeout(() => {
        this.splashScreen.hide();
      }, 3000);

      this.requestService.post('onesignal/key?device_type=' + config.plf, []).then(data => {
        if (data.status) {

          //set one signal id for web to init
          config.osid = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.key = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.fireid = data.firebase_id;

          //For One Signal Fix
          if ((this.platform.is('android') || this.platform.is('ios')) && !this.appService.isMobileWeb()) {

            window.plugins.OneSignal.setAppId((this.appService.appObject.oneSignalConfig.key));
            window.plugins.OneSignal.promptForPushNotificationsWithUserResponse();

            window.plugins.OneSignal.getDeviceState((stateChanges) => {
              if (stateChanges.hasNotificationPermission && stateChanges.userId) {
                this.appService.appObject.deviceUUID = stateChanges.userId;
                this.doUpdate();
              }
            });

            // ............... OLD Method ..............
            // this.oneSignal.startInit(this.appService.appObject.oneSignalConfig.key, this.appService.appObject.oneSignalConfig.fireid);
            // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
            // this.oneSignal.handleNotificationReceived().subscribe(data => {
            //   // do something when notification is received 
            // });
            // this.oneSignal.handleNotificationOpened().subscribe(data => {
            //   // do something when a notification is opened
            // });
            // this.oneSignal.endInit();

            // this.oneSignal.getIds().then(identity => {
            //   //this.appService.showAlert('success','success',JSON.stringify(identity));
            //   this.appService.appObject.deviceUUID = identity.userId;
            //   this.doUpdate();
            // }, err => {
            //   //this.appService.showAlert('error','error',err);
            // });

          }

        }
      });

    });
  }

  call() {
    // console.log('this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1 : ', this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1);
    // console.log('this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1 : ', this.appService.appObject.settings.main_restaurant.order_mobile_number_1);
    let mobileNumber = '';
    if (this.appService.appObject) {
      if (this.appService.appObject.selectedRestaurantDetails && this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1) {
        mobileNumber = this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1;
      } else if (this.appService.appObject.settings && this.appService.appObject.settings.main_restaurant && this.appService.appObject.settings.main_restaurant.order_mobile_number_1) {
        mobileNumber = this.appService.appObject.settings.main_restaurant.order_mobile_number_1;
      }
    }

    // removes all white spaces from mobile number string  
    if (mobileNumber) {
      mobileNumber = mobileNumber.replace(/\s/g, '');
    }

    if (mobileNumber) {
      this.callNumber.callNumber(mobileNumber, true)
        .then(res => console.log('Launched dialer!', res))
        .catch(err => console.log('Error launching dialer', err));
    } else {
      console.log('mobile number not found');
    }
  }

  async doUpdate() {
    console.log('DO_UPDATEEEEE');
    await this.appService.deviceRegister(true);
    await this.appService.updateAppObject();
  }

  cookiesAccepted() {
    this.appService.appObject.isCookiesAccepted = true;
    this.appService.updateAppObject();
  }

  cookiesBannerDismissed() {
    if (this.appService?.appObject?.settings?.pages) {
      var page = this.appService.appObject.settings.pages.filter(function (el) {
        return el.slug == "pnp";
      });
      if (typeof page[0] !== "undefined") {
        page = page[0];
        this.openPage(page.slug, page.name || 'Privacy Policy');
      } else {
        this.cookiesBannerDismissedNotPage();
      }
    } else {
      this.cookiesBannerDismissedNotPage();
    }
  }

  cookiesBannerDismissedNotPage() {
    let cookiesBanner = document.getElementById('cookies-banner');
    if (cookiesBanner) {
      cookiesBanner.remove();
    }
  }

  displayCookieBanner() {
    if ((this.appService.isWebVersion || ((this.platform.is('android') || this.platform.is('ios')) && this.appService.isMobileWeb())) && (this.appService.appObject && !this.appService.appObject.isCookiesAccepted)) {
      return true;
    }
    return false;
  }

  displayLinks(slug) {
    if (this.appService.appObject && this.appService.appObject.settings && this.appService.appObject.settings.pages) {
      if (this.appService.appObject.settings.pages.length) {
        // console.log('this.appService.appObject.currentLang : ', slug + '-' + this.appService.appObject.currentLang);
        let page = this.appService.appObject.settings.pages.find((page) => page.slug === (slug + '-' + this.appService.appObject.currentLang) && page.status === 'true')
        if (page) {
          this.pages[slug] = page;
          return true;
        }
        else return false;
      }
    }
  }

  async openSlug(slug, title) {
    if (this.appService.isWebVersion) {
      const modal = await this.modalController.create({
        component: ModalPopoverPage,
        cssClass: 'order-popup',
        componentProps: {
          'type': 'pages',
          'title': title,
          'slug': (slug + '-' + this.appService.appObject.currentLang)
        }
      });

      await modal.present();
    } else {
      this.router.navigate(['pages/' + (slug + '-' + this.appService.appObject.currentLang)]);
    }
  }

  async openPage(slug, title) {
    const modal = await this.modalController.create({
      component: ModalPopoverPage,
      cssClass: 'order-popup',
      componentProps: {
        'type': 'pages',
        'title': title,
        'slug': slug
      }
    });

    await modal.present();
  }

  triggerHomeRefresh() {
    this.appService.getSettings();
  }

  getTranslateText() {
    let lang = {
      en: {
        woops: "Oops!",
        msg: "Please check your connection and try again"
      },
      de: {
        woops: "Hoppla!",
        msg: "Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut"
      }
    }
    if (this.appService.appObject.currentLang == 'en') this.noInternet = lang.en;
    if (this.appService.appObject.currentLang == 'de') this.noInternet = lang.de;
  }
}
